import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, MenuItem, Select, InputLabel, FormControl, Grid, Typography, Box, Paper, IconButton, Snackbar, Alert } from '@mui/material';
import { AddCircleOutline, DeleteOutline, ArrowBack } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const EditarProducto = () => {
  const { productoId } = useParams();

  const [nombre, setNombre] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [peso, setPeso] = useState('');
  const [precio, setPrecio] = useState('');
  const [precioDesc, setPrecioDesc] = useState('');
  const [altura, setAltura] = useState('');
  const [ancho, setAncho] = useState('');
  const [largo, setLargo] = useState('');
  const [material, setMaterial] = useState('');

  const [tallas, setTallas] = useState([]);
  const [colores, setColores] = useState([]);
  const [imagenesPorColor, setImagenesPorColor] = useState({});
  const [imagenesNuevasPorColor, setImagenesNuevasPorColor] = useState({}); // Para archivos nuevos
  const [variaciones, setVariaciones] = useState([{ tallaId: '', colorId: '', cantStock: '' }]);
  const [categorias, setCategorias] = useState([]);
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState('');
  const [open, setOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Cargar datos del producto, variaciones y imágenes al montar el componente
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtener los datos del producto
        const productoResponse = await axios.get(`${process.env.REACT_APP_BACK_URL}/productos/${productoId}`, { withCredentials: true });
        const producto = productoResponse.data[0];

        // Actualizar los estados con los datos del producto
        setNombre(producto.nombre);
        setDescripcion(producto.descripcion);
        setPrecio(producto.precio);
        setPrecioDesc(producto.precio_descuento);
        setCategoriaSeleccionada(producto.categoria_id);
        setMaterial(producto.material);

        // Obtener las variaciones (stock)
        const stockResponse = await axios.get(`${process.env.REACT_APP_BACK_URL}/stock/${productoId}`, { withCredentials: true });
        const variacionesFormateadas = stockResponse.data.map((stock) => ({
          tallaId: stock.talla_id,
          colorId: stock.color_id,
          cantStock: stock.cant_stock,
        }));
        setVariaciones(variacionesFormateadas);

        // Obtener las tallas y colores para los selects
        const [tallasResponse, coloresResponse, categoriasResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_BACK_URL}/tallas`, { withCredentials: true }),
          axios.get(`${process.env.REACT_APP_BACK_URL}/colores`, { withCredentials: true }),
          axios.get(`${process.env.REACT_APP_BACK_URL}/categorias`, { withCredentials: true })
        ]);
        setTallas(tallasResponse.data);
        setColores(coloresResponse.data);
        setCategorias(categoriasResponse.data);

        // Obtener imágenes por color (rutas absolutas)
        const imagenesResponse = await axios.get(`${process.env.REACT_APP_BACK_URL}/imagenes/${productoId}`, { withCredentials: true });
        const imagenes = imagenesResponse.data.reduce((acc, img) => {
          if (!acc[img.color_id]) acc[img.color_id] = [];
          acc[img.color_id].push(img.ruta_imagen);
          return acc;
        }, {});
        setImagenesPorColor(imagenes);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [productoId]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleCategoriaChange = (event) => {
    setCategoriaSeleccionada(event.target.value);
  };

  const handleVariacionChange = (index, e) => {
    const newVariaciones = [...variaciones];
    newVariaciones[index][e.target.name] = e.target.value;
    setVariaciones(newVariaciones);
  };

  const agregarVariacion = () => {
    setVariaciones([...variaciones, { tallaId: '', colorId: '', cantStock: '' }]);
  };

  const eliminarVariacion = (index) => {
    const newVariaciones = variaciones.filter((_, i) => i !== index);
    setVariaciones(newVariaciones);
  };

  const handleImageChange = (e, colorId) => {
    const files = Array.from(e.target.files);
    
    // Crear URLs de blob para las nuevas imágenes
    const fileURLs = files.map(file => ({
      file: file,
      url: URL.createObjectURL(file)
    }));

    setImagenesNuevasPorColor(prev => ({
      ...prev,
      [colorId]: [...(prev[colorId] || []), ...fileURLs]
    }));
  };

  const handleImageRemove = (colorId, index, isNewImage = false) => {
    if (isNewImage) {
      setImagenesNuevasPorColor(prev => {
        const newImages = prev[colorId].filter((_, i) => i !== index);
        // Liberar la URL del blob
        URL.revokeObjectURL(prev[colorId][index].url);
        return { ...prev, [colorId]: newImages };
      });
    } else {
      setImagenesPorColor(prev => {
        const newImages = prev[colorId].filter((_, i) => i !== index);
        return { ...prev, [colorId]: newImages };
      });
    }
  };

  // Limpiar las URLs de blob cuando el componente se desmonte
  useEffect(() => {
    return () => {
      Object.values(imagenesNuevasPorColor).forEach(images => {
        images.forEach(img => URL.revokeObjectURL(img.url));
      });
    };
  }, [imagenesNuevasPorColor]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append('nombre', nombre);
    formData.append('descripcion', descripcion);
    formData.append('precio', precio);
    formData.append('precio_descuento', precioDesc);
    formData.append('categoria_id', categoriaSeleccionada);
    formData.append('material', material);

    formData.append('stockVariaciones', JSON.stringify(variaciones));

    // Agregar las imágenes nuevas al FormData
    Object.keys(imagenesNuevasPorColor).forEach((colorId) => {
      const files = imagenesNuevasPorColor[colorId];
      files.forEach((fileObj, index) => {
        formData.append(`imagenes_${colorId}_${index}`, fileObj.file);
      });
    });

    formData.append("imagenesPorColor", JSON.stringify(imagenesPorColor));

    axios
      .put(`${process.env.REACT_APP_BACK_URL}/productos/${productoId}`, formData, { withCredentials: true })
      .then((res) => {
        setAlertMessage(res.data.message);
        setAlertSeverity('success'); 
        setOpen(true);

        setTimeout(() => {
            navigate('/admin');
          }, 700);
        })
      .catch((err) => {
        console.error(err);
        setAlertMessage('Hubo un error al actualizar el producto');
        setAlertSeverity('error'); 
        setOpen(true);
      });
  };

  return (
    <Box sx={{ padding: 4, mt: isMobile ? 14 : 8 }}>
      <IconButton onClick={() => navigate('/admin')} sx={{ mb: 2 }}>
        <ArrowBack />
      </IconButton>
      <Typography variant="h3" gutterBottom align="center">Editar Producto</Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Nombre"
              fullWidth
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Descripción"
              fullWidth
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
            <InputLabel id="categoria-label" style={{ backgroundColor: 'white', padding: '0 4px' }}>Categoría</InputLabel>
            <Select
              labelId="categoria-label"
              value={categoriaSeleccionada}
              onChange={handleCategoriaChange}
            >
              <MenuItem value="">Seleccione categoría</MenuItem>
              {categorias.map((categoria) => (
                <MenuItem key={categoria.id} value={categoria.id}>
                  {categoria.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Material"
              fullWidth
              value={material}
              onChange={(e) => setMaterial(e.target.value)}
            />
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <TextField
              label="Precio"
              fullWidth
              value={precio}
              onChange={(e) => setPrecio(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Precio con descuento"
              fullWidth
              value={precioDesc}
              onChange={(e) => setPrecioDesc(e.target.value)}
            />
          </Grid>
        </Grid>

        {/* Variaciones de talla y color */}
        <Box mt={8}>
          <Typography variant="h4" gutterBottom>Variaciones de Producto</Typography>
          {variaciones.map((variacion, index) => (
            <Grid container spacing={3} key={index} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={1}>
                <FormControl fullWidth>
                  <InputLabel style={{ backgroundColor: 'white', padding: '0 4px' }}>Talla</InputLabel>
                  <Select
                    value={variacion.tallaId}
                    name="tallaId"
                    onChange={(e) => handleVariacionChange(index, e)}
                  >
                    <MenuItem value="">Seleccione talla</MenuItem>
                    {tallas.map((talla) => (
                      <MenuItem key={talla.id} value={talla.id}>
                        {talla.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={1.5}>
                <FormControl fullWidth>
                  <InputLabel style={{ backgroundColor: 'white', padding: '0 4px' }}>Color</InputLabel>
                  <Select
                    value={variacion.colorId}
                    name="colorId"
                    onChange={(e) => handleVariacionChange(index, e)}
                  >
                    <MenuItem value="">Seleccione color</MenuItem>
                    {colores.map((color) => (
                      <MenuItem key={color.id} value={color.id}>
                        {color.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={1}>
                <TextField
                  label="Cantidad en stock"
                  fullWidth
                  type="number"
                  value={variacion.cantStock}
                  name="cantStock"
                  onChange={(e) => handleVariacionChange(index, e)}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <IconButton onClick={() => eliminarVariacion(index)} aria-label="Eliminar variación">
                  <DeleteOutline />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button variant="outlined" onClick={agregarVariacion} sx={{ mt: 4 }}>
            Agregar Variación
          </Button>
        </Box>

        {/* Carga de imágenes por color */}
        <Box mt={8}>
          <Typography variant="h4" gutterBottom sx={{ marginBottom: 2 }}>Imágenes por Color</Typography>
          {[...new Set(variaciones.map((variacion) => variacion.colorId))].map((colorId) => {
            const color = colores.find((c) => c.id === colorId);
            if (!color) return null;

            return (
              <Box key={color.id} mb={4}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                  {color.nombre}
                </Typography>
                <Button
                  variant="outlined"
                  component="label"
                  startIcon={<AddCircleOutline />}
                >
                  Cargar Imágenes
                  <input
                    type="file"
                    hidden
                    multiple
                    onChange={(e) => handleImageChange(e, color.id)}
                  />
                </Button>
                <Box mt={2}>
                  {/* Mostrar imágenes existentes */}
                  {imagenesPorColor[color.id] &&
                    imagenesPorColor[color.id].map((imagen, i) => (
                      <Paper
                        key={`existing-${i}`}
                        sx={{ 
                          display: 'inline-block', 
                          position: 'relative', 
                          marginRight: 2, 
                          marginBottom: 2, 
                          maxWidth: '10vw',
                          boxShadow: 'none'
                         }}
                      >
                        <Box
                          component="img"
                          src={imagen}
                          alt={`Imagen ${i + 1}`}
                          sx={{
                            width: '100%',
                            height: 'auto',
                            objectFit: 'cover',
                            borderRadius: 1,
                          }}
                        />
                        <IconButton
                          onClick={() => handleImageRemove(color.id, i, false)}
                          sx={{ position: 'absolute', top: 0, right: 0 }}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </Paper>
                    ))}
                  
                  {/* Mostrar imágenes nuevas */}
                  {imagenesNuevasPorColor[color.id] &&
                    imagenesNuevasPorColor[color.id].map((imagen, i) => (
                      <Paper
                        key={`new-${i}`}
                        sx={{ 
                          display: 'inline-block', 
                          position: 'relative', 
                          marginRight: 2, 
                          marginBottom: 2, 
                          maxWidth: '10vw',
                          boxShadow: 'none'
                         }}
                      >
                        <Box
                          component="img"
                          src={imagen.url}
                          alt={`Nueva imagen ${i}`}
                          sx={{
                            width: '100%',
                            height: 'auto',
                            objectFit: 'cover',
                            borderRadius: 1,
                          }}
                        />
                        <IconButton
                          onClick={() => handleImageRemove(color.id, i, true)}
                          sx={{ position: 'absolute', top: 0, right: 0 }}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </Paper>
                    ))}
                </Box>
              </Box>
            );
          })}
        </Box>

        <Button variant="contained" color="primary" type="submit" sx={{ mt: 4 }}>
          Guardar Cambios
        </Button>
      </form>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EditarProducto;