import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, MenuItem, Select, InputLabel, FormControl, Grid, Typography, Box, Paper, IconButton, Snackbar, Alert } from '@mui/material';
import { AddCircleOutline, DeleteOutline, ArrowBack } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const CrearProducto = () => {
  const [nombre, setNombre] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [precio, setPrecio] = useState('');
  const [precioDesc, setPrecioDesc] = useState('');
  const [material, setMaterial] = useState('');

  const [tallas, setTallas] = useState([]);
  const [colores, setColores] = useState([]);
  const [imagenesPorColor, setImagenesPorColor] = useState({});
  const [variaciones, setVariaciones] = useState([{ tallaId: '', colorId: '', cantStock: '' }]);
  const [coloresUnicos, setColoresUnicos] = useState([]);
  const [variacionesPrevias, setVariacionesPrevias] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState('');
  const [open, setOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertSeverity, setAlertSeverity] = useState('success'); 
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchTallasAndColores = async () => {
      try {
        const [tallasResponse, coloresResponse, categoriasResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_BACK_URL}/tallas`, { withCredentials: true }),
          axios.get(`${process.env.REACT_APP_BACK_URL}/colores`, { withCredentials: true }),
          axios.get(`${process.env.REACT_APP_BACK_URL}/categorias`, { withCredentials: true })
        ]);
        setTallas(tallasResponse.data);
        setColores(coloresResponse.data);
        setCategorias(categoriasResponse.data);
      } catch (error) {
        console.error('Error fetching tallas, colores, and categorias:', error);
      }
    };
    fetchTallasAndColores();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleCategoriaChange = (event) => {
    setCategoriaSeleccionada(event.target.value);
  };

  // Manejar cambio de imagen
  const handleImageChange = (e, colorId) => {
    const files = Array.from(e.target.files);
    setImagenesPorColor((prev) => ({
      ...prev,
      [colorId]: [...(prev[colorId] || []), ...files],
    }));
  };

  // Eliminar una imagen
  const handleImageRemove = (colorId, index) => {
    setImagenesPorColor((prev) => {
      const newImages = prev[colorId].filter((_, i) => i !== index);
      return { ...prev, [colorId]: newImages };
    });
  };

  const agregarVariacion = () => {
    setVariaciones([...variaciones, { tallaId: '', colorId: '', cantStock: '' }]);
  };

  const handleVariacionChange = (index, e) => {
    const newVariaciones = [...variaciones];
    const prevVariaciones = [...variacionesPrevias];
    const prevColorId = prevVariaciones[index]?.colorId;
  
    newVariaciones[index][e.target.name] = e.target.value;
    setVariaciones(newVariaciones);
  
    if (e.target.name === 'colorId') {
      const colorId = e.target.value;
      const color = colores.find(c => c.id === colorId);
  
      // Eliminar el color anterior si ya no está presente en ninguna variación
      if (prevColorId && prevColorId !== colorId) {
        const colorUsado = newVariaciones.some(variacion => variacion.colorId === prevColorId);
        if (!colorUsado) {
          setColoresUnicos(prevColoresUnicos => prevColoresUnicos.filter(c => c.id !== prevColorId));
        }
      }
  
      // Actualizar coloresUnicos
      if (color && !coloresUnicos.some(c => c.id === colorId)) {
        setColoresUnicos(prevColoresUnicos => [...prevColoresUnicos, color]);
      }
  
      // Actualizar variacionesPrevias
      prevVariaciones[index] = { ...newVariaciones[index] };
      setVariacionesPrevias(prevVariaciones);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append('nombre', nombre);
    formData.append('descripcion', descripcion);
    formData.append('precio', precio);
    formData.append('precio_descuento', precioDesc);
    formData.append('categoria_id', categoriaSeleccionada);
    formData.append('material', material);

    // Enviar variaciones (con stock)
    formData.append('stockVariaciones', JSON.stringify(variaciones));

    // Enviar imágenes por color
    Object.keys(imagenesPorColor).forEach((colorId) => {
      const files = imagenesPorColor[colorId];
      for (let i = 0; i < files.length; i++) {
        formData.append(`imagenes_${colorId}`, files[i]);
      }
    });

    axios
      .post(`${process.env.REACT_APP_BACK_URL}/crear_producto`, formData, { 
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true })
      .then((res) => {
        setAlertMessage(res.data.message);
        setAlertSeverity('success'); 
        setOpen(true);

        setTimeout(() => {
            navigate('/admin');
          }, 700);
        })
      .catch((err) => {
        console.error(err);
        const errorMessage = err.response?.data?.error || 'Hubo un error al crear el producto';
        setAlertMessage(errorMessage);
        setAlertSeverity('error'); 
        setOpen(true);
      });
  };

  const eliminarVariacion = (index) => {
    setVariaciones(variaciones.filter((_, i) => i !== index));

    const colorId = variaciones[index].colorId;
    const isLastColor = !variaciones.some((v, i) => v.colorId === colorId && i !== index);
    if (isLastColor) {
      setColoresUnicos(coloresUnicos.filter((color) => color.id !== colorId));
    }
  };

  return (
    <Box sx={{ padding: 4, marginTop: isMobile ? 14 : 8 }}>
      <IconButton onClick={() => navigate('/admin')} sx={{ mb: 2 }}>
        <ArrowBack />
      </IconButton>
      <Typography variant="h3" gutterBottom align="center">Crear Producto</Typography>
      <form onSubmit={handleSubmit}>
        {/* Información básica del producto */}
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Nombre"
              fullWidth
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Descripción"
              fullWidth
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
            <InputLabel id="categoria-label" style={{ backgroundColor: 'white', padding: '0 4px' }}>Categoría</InputLabel>
            <Select
              labelId="categoria-label"
              value={categoriaSeleccionada}
              onChange={handleCategoriaChange}
            >
              <MenuItem value="">Seleccione categoría</MenuItem>
              {categorias.map((categoria) => (
                <MenuItem key={categoria.id} value={categoria.id}>
                  {categoria.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <TextField
              label="Material"
              fullWidth
              value={material}
              onChange={(e) => setMaterial(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Precio"
              fullWidth
              value={precio}
              onChange={(e) => setPrecio(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Precio con descuento"
              fullWidth
              value={precioDesc}
              onChange={(e) => setPrecioDesc(e.target.value)}
            />
          </Grid>
        </Grid>

        {/* Variaciones de talla y color */}
        <Box mt={8}>
          <Typography variant="h4" gutterBottom>Variaciones de Producto</Typography>
          {variaciones.map((variacion, index) => (
            <Grid container spacing={3} key={index} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={1}>
                <FormControl fullWidth>
                <InputLabel style={{ backgroundColor: 'white', padding: '0 4px' }}>Talla</InputLabel>
                  <Select
                    value={variacion.tallaId}
                    name="tallaId"
                    onChange={(e) => handleVariacionChange(index, e)}
                  >
                    <MenuItem value="">Seleccione talla</MenuItem>
                    {tallas.map((talla) => (
                      <MenuItem key={talla.id} value={talla.id}>
                        {talla.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={1.5}>
                <FormControl fullWidth> 
                <InputLabel style={{ backgroundColor: 'white', padding: '0 4px' }}>Color</InputLabel>
                  <Select
                    value={variacion.colorId}
                    name="colorId"
                    onChange={(e) => handleVariacionChange(index, e)}
                  >
                    <MenuItem value="">Seleccione color</MenuItem>
                    {colores.map((color) => (
                      <MenuItem key={color.id} value={color.id}>
                        {color.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={1}>
                <TextField
                  label="Cantidad en stock"
                  fullWidth
                  type="number"
                  value={variacion.cantStock}
                  name="cantStock"
                  onChange={(e) => handleVariacionChange(index, e)}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <IconButton onClick={() => eliminarVariacion(index)} aria-label="Eliminar variación">
                  <DeleteOutline />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button variant="outlined" onClick={agregarVariacion} sx={{ mt: 4 }}>
            Agregar Variación
          </Button>
        </Box>

        {/* Carga de imágenes por color */}
        <Box mt={8}>
          <Typography variant="h4" gutterBottom sx={{ marginBottom: 2 }}>Imágenes por Color</Typography>
          {/* Solo mostrar si hay colores únicos */}
          {coloresUnicos.length > 0 ? (
            coloresUnicos.map((color, index) => (
              <Box key={index} mb={3}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                  {color.nombre}
                </Typography>

                {/* Carga de imágenes */}
                  <Box display="flex" flexDirection="column" gap={2}>
                    {/* Botón para cargar nuevas imágenes */}
                    <Button
                      variant="outlined"
                      component="label"
                      startIcon={<AddCircleOutline />}
                      sx={{ width: 'fit-content' }} // Ajusta el ancho del botón
                    >
                      Cargar Imágenes
                      <input
                        type="file"
                        hidden
                        multiple
                        onChange={(e) => handleImageChange(e, color.id)}
                      />
                    </Button>

                  {/* Previsualización de imágenes */}
                  {imagenesPorColor[color.id] && (
                    <Grid container spacing={2}>
                      {imagenesPorColor[color.id].map((file, i) => (
                        <Grid item key={i}>
                          <Paper sx={{ 
                              display: 'inline-block', 
                              position: 'relative', 
                              marginRight: 2, 
                              marginBottom: 2, 
                              maxWidth: '10vw',
                              boxShadow: 'none'
                            }}>
                            <Box
                              component="img"
                              src={URL.createObjectURL(file)}
                              alt={`Imagen para ${color.nombre}`}
                              sx={{
                                width: '100%',
                                height: 'auto',
                                objectFit: 'cover',
                                borderRadius: 1,
                              }}
                            />
                            {/* Botón para eliminar la imagen */}
                            <IconButton
                              size="small"
                              onClick={() => handleImageRemove(color.id, i)}
                              sx={{ position: 'absolute', top: 0, right: 0 }}
                            >
                              <DeleteOutline />
                            </IconButton>
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Box>
              </Box>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">No hay colores seleccionados para mostrar imágenes.</Typography>
          )}
        </Box>

        {/* Botón para enviar el formulario */}
        <Box mt={4}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ width: 'fit-content' }} // Ajusta el ancho del botón al contenido
          >
            Crear Producto
          </Button>
        </Box>
        </form>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CrearProducto;