import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
  h1: {
    fontFamily: 'Montserrat, sans-serif',
  },
  h2: {
    fontFamily: 'Montserrat, sans-serif',
  },
  h3: {
    fontFamily: 'Montserrat, sans-serif',
  },
  h4: {
    fontFamily: 'Montserrat, sans-serif',
  },
  h5: {
    fontFamily: 'Montserrat, sans-serif',
  },
  h6: {
    fontFamily: 'Montserrat, sans-serif',
  },
  subtitle1: {
    fontFamily: 'Open Sans, sans-serif',
  },
  subtitle2: {
    fontFamily: 'Open Sans, sans-serif',
  },
  body1: {
    fontFamily: 'Open Sans, sans-serif',
  },
  palette: {
    primary: {
      main: '#211915', // Negro claro
    },
    secondary: {
      main: '#cab3a1', // Beige
    },
    error: {
      main: '#f44336', // rojo
    },
    alert: {
      main: '#8E0016' // bordo
    }
  },
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();