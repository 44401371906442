import React from 'react';
import { AppBar, Box, Grid, IconButton, Toolbar, Typography, useTheme, Card, CardContent } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link } from 'react-router-dom';
import { keyframes } from '@emotion/react';

const shine = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const HeaderDesktop = ({ totalCantidadCarrito, setDrawerOpen }) => {
  const theme = useTheme();

  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#ffff' }}>
      <Toolbar>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <img src="/one_way.png" alt="ONE WAY" style={{ height: '40px', maxWidth: '150px' }} />
            </Link>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography variant="body1" sx={{ color: theme.palette.primary.main }}>
                Seguinos en
              </Typography>
              <a href="https://www.instagram.com/oneway.style" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                <IconButton
                  color="inherit"
                  sx={{
                    color: theme.palette.primary.main,
                    ml: -2,
                    '&:hover': {
                      color: theme.palette.secondary.main,
                    },
                  }}
                >
                  <InstagramIcon />
                </IconButton>
              </a>
              <Typography variant="body1" sx={{ color: theme.palette.primary.main }}>
                Consultas al
              </Typography>
              <a href="https://api.whatsapp.com/send/?phone=5492235993095&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                <IconButton
                  color="inherit"
                  sx={{
                    color: theme.palette.primary.main,
                    ml: -2,
                    '&:hover': {
                      color: theme.palette.secondary.main,
                    },
                  }}
                >
                  <WhatsAppIcon />
                </IconButton>
              </a>
            </Box>
          </Grid>
          <Grid item>
            <IconButton color="inherit" onClick={() => setDrawerOpen(true)}>
              <ShoppingCartIcon sx={{ color: '#211915' }} />
              <Typography variant="body1" sx={{ ml: 1, color: '#211915' }}>
                ({totalCantidadCarrito})
              </Typography>
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
      <Grid container sx={{ mt: 0 }}>
        <Card sx={{ 
          width: '100%', 
          borderRadius: 0, 
          padding: 0, 
          backgroundColor: 'rgb(149,16,22)', 
          color: 'white',
          position: 'relative',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(90deg, transparent, rgba(247, 238, 231, 0.8), transparent)',
            animation: `${shine} 4s cubic-bezier(0.4, 0, 0.2, 1) infinite`
          }
        }}>
          <CardContent sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            position: 'relative',
            zIndex: 1
          }}>
            <Typography variant="body1" sx={{ textAlign: 'center', flexGrow: 1 }}>
              Si pagas por transferencia, tenés un 10% de descuento. Escribinos al WhatsApp ;)
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </AppBar>
  );
};

export default HeaderDesktop;